
import {add} from 'ionicons/icons';
import BaseLayout from "@/components/BaseLayout.vue";
import {
  IonInput, IonItem, IonTextarea, IonLabel,
  IonCheckbox, IonItemGroup, IonToggle, IonButton, IonIcon, alertController
} from "@ionic/vue";

function initialState(){
  return{
    activity:{
      name: '',
      description:'',
      type:'',
      created:'',
      startTime:'',
      distance:'',
      locationInstruction: '',
      address:'',
      group:{},
      privateActivity: false,
      monday:false,
      tuesday:false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false
    },
    address: {
      address:'',
      city:'',
      state:'',
      zipcode:''
    },
    isRepeat: false,
    repeat : [
      { val: 'Monday', isChecked: false },
      { val: 'Tuesday', isChecked: false },
      { val: 'Wednesday', isChecked: false },
      { val: 'Thursday', isChecked: false },
      { val: 'Friday', isChecked: false },
      { val: 'Saturday', isChecked: false },
      { val: 'Sunday', isChecked: false },
    ]
  }
}


export default  {
  name: 'CreateActivity',
  components: {
    BaseLayout,
    IonInput,
    IonItem,
    IonTextarea,
    IonCheckbox,
    IonItemGroup,
    IonToggle,
    IonButton,
    IonIcon,
    IonLabel
  },
  created(){
    this.loadProfile();
  },
  setup() {
    return {
      add,
    }
  },
  computed:{
  },
  methods:{
    loadProfile(){
      return this.$store.dispatch('account');
    },
    repeatActivityToggle(){
      this.isRepeat = !this.isRepeat;
      if(!this.isRepeat){
        this.repeat.forEach( day => day.isChecked =false);
      }
    },
    createActivity(){
      if(this.$route.params.groupId){
        this.activity.group = { id:this.$route.params.groupId }
        if(this.isRepeat){
          this.repeat.forEach( day => {
            this.activity[day.val.toLowerCase()] = day.isChecked;
          });
        }
        this.$store.dispatch('activity/createActivity',this.activity).then(
            () => {
                this.reset();
                this.$router.replace("/");
            }
        ).catch((error) => {
          this.presentAlert(error.response.data.title);
        });
      }else{
        this.presentAlert('No Group associated to this activity');
      }
    },
    reset(){
      Object.assign(this.$data, initialState());
    },
    async presentAlert(error) {
      const alert = await alertController.create({
        header: 'Error',
        message: error,
        buttons: ['OK']
      });
      await alert.present();
    },

  },

  data(){
    return initialState();
  }
}
